@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');


*{
  padding: 0 ;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.select__control{
  height:48px !important;
}

.cover-image{
  background: url('./Components/assets/image.png');
  /* object-fit: cover; */
  /* background-position: -80px -157px; */
  /* background-position: center; */
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px; 
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}

::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 50%;
  
  cursor: pointer;
}

/* Default state */
input[type="radio"]:checked {
  background-color: #382F8A; /* Change this color to your preferred one */
}

.ql-container .ql-editor {
  font-size: 16px !important; /* Default font size */
}

