/* src/styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@layer components {
  
  .accordion-header.active svg:first-child {
    @apply transition ease-in-out duration-300;
    transform: rotate(90deg);
  }

  .ql-container.ql-snow {
    @apply border-none !important;
  }

  .ql-editor{
    @apply px-0 !important;
  }

  .ql-toolbar.ql-snow{
    @apply shadow-custom !important;
  }
  .screen {
    @apply bg-gray-500 !important;
  }
}
