.accordion{
    background-color: #fafafa;
    border-left: 0.3rem solid gray;
    border-radius: 0.5rem
}

.testing{
    padding: 0em 1em;
}

.testing-2{
    border-left: 3px solid #0b2e4a;
    padding: 0.8em;
}

.card{
    /* border-radius: 0 !important; */
    margin-top: 5px; 
    /* padding: 1em;    */
}

.card-header{
    /* background-color: rgb(240, 32, 240);
    border-radius: 100px !important; */
    border: none;
    font-size: 0.8rem;
    font-weight: medium;
    font-family: 'Inter', sans-serif;
}

.add-semester-button-container{
    padding: 5px 0px;
}

.bg-glow{
    border-radius: 50%;
    padding: 5px;
}

.bg-glow:hover{
    background-color: rgb(194, 194, 194);
}

.add-buttons{
    font-size: 0.8rem;
}

.accordion-collapse{
    overflow: hidden !important;
}

.highlight{
    background-color: #0b2e4a;
}
.highlight label{
    color:white;
}
.highlight span{
    color:white;
}
.highlight button{
    background-color: transparent !important;
    color:white;
}

.highlight i{
    background-color: transparent !important;
    color:white;
}

.test-icon{
    position: absolute;
    margin-left:3px ;
    top: 30%;
    left:0;
}

/* new css for the buttons  */

/* General Button Styling */
.custom_button_new {
    font-family: Arial, sans-serif;
    font-size: 10px;
    padding: 5px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
  
  /* Add Topic Button */
  .custom_button_new.add-topic {
    background-color: #444;
    color: white;
  }
  
  /* Slide Group and Test Buttons */
  .custom_button_new.slide-group,
  .custom_button_new.test {
    background-color: #e8e8e8;
    color: #444;
    border: 1px solid #ccc;
  }
  
  /* Save Course Button */
  .custom_button_new.save-course {
    background-color: #444;
    color: white;
  }
  
  /* Publish Button */
  .custom_button_new.publish {
    background-color: #f0f0f0;
    color: #888;
    border: 1px solid #ccc;
    font-weight: bold;
  }
  
  /* Plus Button for Adding New Slide */
  .custom_button_new.add-slide {
    background-color: #f8f8f8;
    color: #444;
    border: 1px solid #ccc;
    font-size: 24px;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom_button_new.view-as-student {
    background-color: transparent;
    color: #444;
    border: 2px solid #d1d5db;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }  